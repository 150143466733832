var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getSoftwareIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.base_data.software.software')),"type":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.software
                  ? 'is-danger'
                  : '',"message":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.software
                  ? _vm.getSoftwareValidationErrors.software
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(
                    _vm.$t('modules.base_data.software.placeholder.software')
                  ),"required":""},model:{value:(_vm.software),callback:function ($$v) {_vm.software=$$v},expression:"software"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.base_data.software.version')),"type":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.version
                  ? 'is-danger'
                  : '',"message":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.version
                  ? _vm.getSoftwareValidationErrors.version
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.base_data.software.placeholder.version')),"required":""},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.base_data.software.updates_until')),"type":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.updates_until_date
                  ? 'is-danger'
                  : '',"message":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.updates_until_date
                  ? _vm.getSoftwareValidationErrors.updates_until_date
                  : ''}},[_c('b-datepicker',{attrs:{"inline":false},model:{value:(_vm.updatesUntilDate),callback:function ($$v) {_vm.updatesUntilDate=$$v},expression:"updatesUntilDate"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
                  _vm.$t('modules.base_data.software.security_updates_until')
                ),"type":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.security_updates_until_date
                  ? 'is-danger'
                  : '',"message":_vm.getSoftwareValidationErrors &&
                _vm.getSoftwareValidationErrors.security_updates_until_date
                  ? _vm.getSoftwareValidationErrors.security_updates_until_date
                  : ''}},[_c('b-datepicker',{attrs:{"inline":false},model:{value:(_vm.securityUpdatesUntilDate),callback:function ($$v) {_vm.securityUpdatesUntilDate=$$v},expression:"securityUpdatesUntilDate"}})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }