const dot = require("dot-object");

export default class Json {
  public static isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public static dot2Nested(obj: any): any {
    if (obj) {
      return dot.object(obj);
    }
  }

  public static removeNulls(obj: any): any {
    if (Object.keys(obj).length > 0) {
      const mObj: any = { ...obj };
      for (const objKey in mObj) {
        if (mObj[objKey] === null) {
          delete mObj[objKey];
        }
      }
      return mObj;
    }
    return obj;
  }
}
