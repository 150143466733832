
import { Component, Prop, Watch } from "vue-property-decorator";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import ISoftware from "@/interfaces/ISoftware";
import Json from "../../../helper/Json";
import { mixins } from "vue-class-component";
import { SoftwareStoreMixin } from "@/mixins/Stores/BaseData/SoftwareStoreMixin";
import { DEFAULT_BACKEND_DATE_FORMAT } from "@/config/Formats";

@Component({
  computed: {
    Json() {
      return Json;
    },
  },
  components: { SaveButton, DefaultForm },
  mixins: [SoftwareStoreMixin, FormMixin],
})
export default class SoftwareForm extends mixins(SoftwareStoreMixin) {
  protected name = "SoftwareForm.vue";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: ISoftware;

  protected id?: number;
  protected software = "";
  protected version = "";
  protected updatesUntilDate: string | Date | null = null;
  protected securityUpdatesUntilDate: string | Date | null = null;
  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: ISoftware) {
    if (data) {
      this.setFormValues(data);
    }
  }

  public getFormValues(): ISoftware {
    let software: ISoftware = {
      software: this.software,
      version: this.version,
      updates_until_date: this.updatesUntilDate
        ? this.$moment(this.updatesUntilDate).format(
            DEFAULT_BACKEND_DATE_FORMAT
          )
        : "",
      security_updates_until_date: this.securityUpdatesUntilDate
        ? this.$moment(this.securityUpdatesUntilDate).format(
            DEFAULT_BACKEND_DATE_FORMAT
          )
        : "",
    };
    if (this.data && this.data.id) {
      software.id = this.id;
    }
    return software;
  }

  protected setFormValues(data: ISoftware): void {
    if (data) {
      this.id = data.id;
      this.software = data.software;
      this.version = data.version;
      this.updatesUntilDate = new Date(data.updates_until_date);
      this.securityUpdatesUntilDate = new Date(
        data.security_updates_until_date
      );
      this.createdAt = data?.created_at ? new Date(data.created_at) : undefined;
      this.updatedAt = data?.updated_at ? new Date(data.updated_at) : undefined;
    }
  }

  protected init() {}
}
